<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      ref="dataForm"
      :model="dataForm"
      :rules="dataRule"
      label-width="80px"
      @keyup.enter.native="dataFormSubmit()"
    >
      <el-form-item label="用户名" prop="userName">
        <el-input v-model="dataForm.userName" placeholder="登录帐号" />
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-dict v-model="dataForm.sex" code="SEX" />
      </el-form-item>
      <el-form-item label="真实姓名" prop="realName">
        <el-input v-model="dataForm.realName" placeholder="真实姓名" />
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="dataForm.email" placeholder="邮箱" />
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-input v-model="dataForm.mobile" placeholder="手机号" />
      </el-form-item>
      <el-form-item v-show="false" label="所属机构" prop="orgNo">
        <!--        <el-dialog-->
        <!--          width="30%"-->
        <!--          append-to-body-->
        <!--          title="选择区域"-->
        <!--          :visible.sync="visiblePopover"-->
        <!--        >-->
        <!--          <el-tree-->
        <!--            ref="orgListTree"-->
        <!--            :data="orgNoOptions"-->
        <!--            :props="orgListTreeProps"-->
        <!--            node-key="orgNo"-->
        <!--            default-expand-all-->
        <!--            :expand-on-click-node="false"-->
        <!--            @current-change="orgListTreeCurrentChangeHandle"-->
        <!--          />-->
        <!--        </el-dialog>-->
        <!--        <el-button @click="visiblePopover = true">-->
        <!--          {{ dataForm.orgName||'点击选择所属机构' }}-->
        <!--        </el-button>-->
      </el-form-item>
      <el-form-item label="角色" prop="roleIdList">
        <el-select
          v-model="dataForm.roleIdList"
          multiple
          clearable
          filterable
          placeholder="请选择"
          class="width185"
        >
          <el-option
            v-for="role in roleList"
            :key="role.roleId"
            :label="role.roleName"
            :value="role.roleId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="公司名称" prop="companyIds">
        <el-select
          v-model="dataForm.companyIds"
          placeholder="公司"
          filterable
          clearable
          multiple
        >
          <el-option
            v-for="(item, index) in companyList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="供应商" prop="orgIds">
        <el-select
          v-model="dataForm.orgIds"
          placeholder="供应商"
          filterable
          clearable
          multiple
        >
          <el-option
            v-for="(item, index) in orgList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="状态" size="mini" prop="status">
        <el-radio-group v-model="dataForm.status">
          <el-radio :label="0"> 禁用 </el-radio>
          <el-radio :label="1"> 正常 </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      orgNoOptions: this.treeDataTranslate(
        JSON.parse(sessionStorage.getItem('orgList') || '[]'),
        'orgNo',
        'parentNo',
      ),
      orgListTreeProps: {
        label: 'orgName',
        children: 'children',
      },
      visible: false,
      visiblePopover: false,
      roleList: [],
      dataForm: {
        orgName: '',
        id: '',
        userName: '',
        sex: '',
        realName: '',
        salt: '',
        email: '',
        mobile: '',
        orgNo: '',
        roleIdList: [],
        status: 1,
        companyIds: [],
        orgIds: [],
      },
      companyList: [],
      orgList: [],
      dataRule: {
        userName: [
          {
            required: true,
            message: '用户名不能为空',
            trigger: 'blur',
          },
        ],
        sex: [
          {
            required: true,
            message: '性别不能为空',
            trigger: 'blur',
          },
        ],
        realName: [
          {
            required: true,
            message: '真实姓名不能为空',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  mounted() {
    this.getCompany();
    this.getOrgList();
  },
  methods: {
    init(id) {
      this.dataForm.id = id || 0;
      this.$http({
        url: '/sys/role/select',
        method: 'get',
      })
        .then(({ data }) => {
          this.roleList = data && data.code === 0 ? data.list : [];
        })
        .then(() => {
          this.visible = true;
          this.$nextTick(() => {
            this.$refs['dataForm'].resetFields();
          });
        })
        .then(() => {
          if (this.dataForm.id) {
            this.$http({
              url: `/sys/user/info/${this.dataForm.id}`,
              method: 'get',
            }).then(({ data }) => {
              if (data && data.code === 0) {
                this.dataForm.userName = data.user.userName;
                this.dataForm.sex = data.user.sex.toString();
                this.dataForm.realName = data.user.realName;
                this.dataForm.salt = data.user.salt;
                this.dataForm.email = data.user.email;
                this.dataForm.mobile = data.user.mobile;
                this.dataForm.orgNo = data.user.orgNo;
                this.dataForm.orgName = this.transOrg(data.user.orgNo);
                this.dataForm.roleIdList = data.user.roleIdList;
                this.dataForm.status = data.user.status;
                this.dataForm.companyIds = data.user.companyIds
                  ? data.user.companyIds
                      .split(',')
                      .map((item) => parseInt(item))
                  : [];
                this.dataForm.orgIds = data.user.orgIds
                  ? data.user.orgIds.split(',').map((item) => parseInt(item))
                  : [];
              }
            });
          } else {
            this.dataForm.orgName = '';
          }
        });
    },
    // 机构树选中
    orgListTreeCurrentChangeHandle(data) {
      this.dataForm.orgNo = data.orgNo;
      this.dataForm.orgName = data.orgName;
      this.visiblePopover = false;
    },
    // 获取公司的信息
    getCompany() {
      this.$http({
        url: `/tc/company/combo`,
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },
    //供应商列表
    getOrgList() {
      this.$http({
        url: `/cc/org/all`,
        method: 'get',
        params: { type: 0, isUse: 1 },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/sys/user/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: {
              userId: this.dataForm.id || undefined,
              userName: this.dataForm.userName,
              sex: this.dataForm.sex,
              realName: this.dataForm.realName,
              salt: this.dataForm.salt,
              email: this.dataForm.email,
              mobile: this.dataForm.mobile,
              orgNo: this.dataForm.orgNo,
              status: this.dataForm.status,
              roleIdList: this.dataForm.roleIdList,
              companyIds: this.dataForm.companyIds.map((i) => i).join(','),
              orgIds: this.dataForm.orgIds.map((i) => i).join(','),
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.visible = false;
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.mod-org {
  .org-list__input,
  .icon-list__input {
    > .el-input__inner {
      cursor: pointer;
    }
  }
  & __icon-popover {
    max-width: 350px;
  }

  & __icon-list {
    max-height: 380px;
    padding: 0;
    margin: -8px 0 0 -8px;

    > .el-button {
      padding: 8px;
      margin: 8px 0 0 8px;

      > span {
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 18px;
        font-size: 18px;
      }
    }
  }
  .icon-list__tips {
    font-size: 18px;
    text-align: center;
    color: #e6a23c;
    cursor: pointer;
  }
}
</style>
